import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SesionGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigate(['caja']);
    // }
    return true;
  }
}
